.sidebar {
    display: flex;
    flex-direction: column;
    flex: 0.4 1;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
    overflow: scroll;
}
.sidebar__header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-right: 1px solid lightgrey;
}

.sidebar__header > .MuiSvgIcon-Root {
    margin-right: 2vh;
    font-size: 24px !important;
}

.sidebar__search {
    display:flex;
    align-items: center;
    background-color: #f6f6f6;
    height: 39px;
    padding: 10px;
}

.sidebar__searchContainer {
    display:flex;
    align-items: center;
    background-color: white;
    width: 100%;
    height: 35px;
    border-radius: 20px;
}

.sidebar__searchContainer  > .MuiSvgIcon-Root {
    color:grey;
    padding: 10px;
}

.sidebar__searchContainer > .MuiIconButton-root:hover {
    width: 100%;
}

.sidebar__searchContainerinput{
    border:none;
    outline-width: 0;
    margin-left: 10px;
    width: inherit;
}