.app {
  display: grid;
  place-items: center;
  height: 100vh;
  background-color: #dadbd3;
}

.app__body {
  display: flex;
  background-color: #ededed;
  margin-top: -50px;
  height: 90vh;
  width: calc(100vw - 250px); /* Adjust this value to fit your needs */
  box-shadow: 12px 12px 2px 1px rgba(0, 0, 255, .2);
}

.aside {
  flex: 0.2;
}