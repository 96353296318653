.ads {
    position: absolute;
    top: 0;
    left: 0;
    width: 300px; /* Adjust this value to fit your needs */
    height: 100%;
    z-index: 1;
  }
  
  .ads img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }