.chat {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.chat__header {
    display: flex;
    padding: 20px;
    align-items: center;
    border-bottom: 1px solid lightgrey;
}

.chat__headerInfo {
    flex: 1;
    padding-left: 20px;
}

.chat__headerInfo > h3 {
    margin-bottom: 3px;
    font-weight: 500;
}

.chat__headerInfo > p {
    color:grey
}

.chat__headerRight {
    display: flex;
    justify-content: space-between;
    min-width: 100px;
}

.chat__body {
    flex:1;
    padding: 30px;
    overflow: scroll;
    background-color: rgb(250, 248, 250);
}

.chat__message {
    position: relative;
    font-size: 16px;
    padding: 10px;
    width: fit-content;
    border-radius: 10px;
    margin-bottom: 30px;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

.chat__name {
  position: absolute;
  top: -15px;
  font-weight: 800;
  font-size: xx-small;
}

.chat__timestamp {
  margin-left: 10px;
  font-size: xx-small;
}

.chat__reciever {
    margin-left: auto;
    background-color: #dcf8c6;
}

.chat__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 62px;
    border-top: 1px solid lightgrey;
}

.chat__footer > form {
    flex:1;
    display: flex;
}

.chat__footer > form > input {
    flex:1;
    border-radius: 30px;
    outline-width: 0;
    padding: 10px;
    border: none
}

.chat__footer > form > button {
   display: none;
}

.chat__footer > .MuiSvgIcon-Root {
    padding: 10px;
    color:grey;
}
